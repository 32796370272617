<template>
    <b-card-code
        title="Add New" 
    >
          <!-- form -->
          <validation-observer
            ref="ScannerAllocationForm"
            #default="{invalid}"      
          >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="updateAllocation"
          >
                <!-- Type-->
                <b-form-group
                  label="Type"
                  label-for="Type">
                    <validation-provider
                      #default="{ errors }"
                      name="asset_type"
                      rules="required">
                        <b-form-select  
                        v-model="asset_type_id" 
                        :options="asset_type_options"
                        :state="errors.length > 0 ? false:null"
                        @change="onTypeChange"
                        ></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <!-- Scanner-->
                <b-form-group
                  label="Scanner"
                  label-for="Scanner">
                    <validation-provider
                      #default="{ errors }"
                      name="agent_id"
                      rules="required">
                        <b-form-select  
                        v-model="agent_id" 
                        :options="agents"
                        :state="errors.length > 0 ? false:null"
                        ></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

               <b-form-group
                  label=""
                  label-for="Is Default">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="is_default"
                        name="is_default"
                        value="true"
                        unchecked-value="false"
                        >
                        Is Default ?
                    </b-form-checkbox>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="invalid"
                >
                  Add
                </b-button>
            </b-form>
          </validation-observer>

  </b-card-code>
</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BForm, BButton,BFormTextarea, BInputGroupAppend,BInputGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol, 
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox
  },
  data() {
    return {
      agent_id:null,
      agents:[{ value: null, text: '--Select Scanner--' }],
      asset_type_id: null, 
      asset_type_options: [{ value: null, text: '--Select Type--' }],
      is_default: false,
    }
  },
    created: function () {
     this.load();
  },
    methods: {

        load: function(){
            // Load asset type drop down
          const a_options = {
              method: 'GET',
              headers: { 'content-type': 'application/json' },
              url: process.env.VUE_APP_BASEURL+'asset/asset-type',
          }
          var self = this;
          this.$http(a_options).then( ( res ) => {
              // console.log(res.data)
              res.data.map(function(value, key) {
                  let type = {
                      value: res.data[key].asset_type_id, 
                      text: res.data[key].asset_type_name
                      };
                  // console.log(res.data[key].profile.organization)
                  self.asset_type_options.push(type)
              });
          })

          const options = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            url: process.env.VUE_APP_BASEURL+'agent/allocation/'+this.$route.params.id+"/",
            }
            this.$http(options).then( ( res ) => {
                console.log(res)
                if(res.data.a_id){
                    // this.$router.push({name: 'Organizations'});
                    this.asset_type_id = res.data.asset_type_id    
                    this.is_default = res.data.is_default   
                    this.onTypeChange()
                    this.agent_id = res.data.agent_id    
                }
            })


        },

        updateAllocation: function() {
            this.$refs.ScannerAllocationForm.validate().then(success => {
            if (success) {
            //call to axios
            console.log("success")
                let data = {
                    agent_id: this.agent_id,
                    a_id: parseInt(this.$route.params.id),
                    // org_id: this.$route.params.id, //this can not be changed
                    asset_type_id: this.asset_type_id,
                    is_default: this.is_default
                }
                const options = {
                    method: 'PATCH',
                    headers: { 'content-type': 'application/json' },
                    data:data,
                    url: process.env.VUE_APP_BASEURL+'agent/allocation/'+this.$route.params.id+"/",
                }
                this.$http(options).then( ( res ) => {
                    if(res.data.a_id){
                        this.$router.push({name: 'Allocated Scanners', params:{id:this.$route.params.id}});
                    }
                })
            }  
         })
        },

        onTypeChange: function(){
            this.agents = []
            const o_options = {
                method: 'GET',
                headers: { 'content-type': 'application/json' },
                url: process.env.VUE_APP_BASEURL+'agent/agent?asset_type='+this.asset_type_id,
            }
            var self = this;
            this.$http(o_options).then( ( res ) => {
                // console.log(res.data)
                res.data.map(function(value, key) {
                    let agent = {
                        value: res.data[key].agent_id, 
                        text: res.data[key].agent_name
                        };
                    // console.log(res.data[key].profile.organization)
                    self.agents.push(agent)
                });
            })         
        },
    
    }
  }
  </script>
